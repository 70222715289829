import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from 'src/app/services/session.service';
import { DataService } from 'src/app/services/data.service';
import { MustMatch } from 'src/app/services/must-match.validator';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

interface SideNavToggle {
  collapsed: boolean;
  screenWidth: number;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() toggleSidebar: any;
  @Output() onToggleSidenav: EventEmitter<SideNavToggle> = new EventEmitter();
  resetPasswordForm: FormGroup;
  updateProfileForm: FormGroup;
  displayErrorBlock: boolean = false;
  resMessage: { message: any; responseType: any; color: any; };
  collapsed: boolean = true;
  screenWidth: number = 0;
  displayNotification: boolean = false;
  userDetails: any;
  searchString : string = "";
  currentUrl: string;
  imageUrl: any;
  serverType: string
  
  constructor(private location:Location, private route: Router, private sessionService: SessionService, private modalService: NgbModal, private formBuilder: FormBuilder, private dataService: DataService,private activatedRoute : ActivatedRoute) {
    this.userDetails = this.sessionService.SessionService_GetUserDetails()
    this.serverType = environment.Server
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth
    if (this.screenWidth <= 992) {
      this.collapsed = false;
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
    if(this.screenWidth >= 992){
      this.collapsed = true;
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
  }

  ngOnInit(): void {
    this.toggleCollapse(false)
    // this.getProfileSettingDataToPatch();
    this.location.onUrlChange((url: string) => {
      this.dismissModal()
      this.searchString='';
    });

    this.dataService.refreshProfile.subscribe(result =>{
      if(result) {
        setTimeout(() => {
          this.userDetails = this.sessionService.SessionService_GetUserDetails();
          this.imageUrl=this.userDetails?.pictureUrl ;
        }, 1000);
        this.resetPasswordFormControls();
        this.updateProfileFormControls();
      }
    })

    this.resetPasswordFormControls();
    this.updateProfileFormControls();
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.hasOwnProperty('query')) {
        this.searchString = params['query'];
      } else {
      }
    })
    this.userDetails = this.sessionService.SessionService_GetUserDetails();
  }

  resetPasswordFormControls() {
    const passwordRejex = /^.{8,}$/;
    const maxPasswordLength = 24;
    this.resetPasswordForm = this.formBuilder.group({
      userId: [this.userDetails.userId],
      emailVerifyKey: [''],
      oldPassword: ['', Validators.required],
      password: ['', [ Validators.required, Validators.pattern(passwordRejex), Validators.maxLength(maxPasswordLength) ]],
      rptPassword: ['', Validators.required],
      orgName: ['']
    },
    { validator: MustMatch('password', 'rptPassword') });
  }

  updateProfileFormControls() {
    let textRejex = /^(?! )(.*)$/;
    this.updateProfileForm = this.formBuilder.group({
      userId: [null],
      role: [null],
      email: [null],
      firstName: [this.userDetails.firstName, [Validators.required, Validators.pattern(textRejex), Validators.minLength(2), Validators.maxLength(32)]],
      lastName: [this.userDetails.lastName, [Validators.required, Validators.pattern(textRejex), Validators.minLength(2), Validators.maxLength(32)]],
      accessCount: [null],
      createdAt: [null],
      deleted: [null],
      deletedAt: [null],
      id: [this.userDetails.id, [Validators.required]],
      inactive: [null],
      lastAccessTS: [null],
      orgName: [null],
      tenantId: [null],
      uid: [null],
      updatedAt: [null],
      emailVerifyKey: [null],
      verifyLinkSentAt: [null],
    })
  } 

  updateProfileFormPatch() {
    this.updateProfileForm.patchValue({
      userId: this.userDetails?.userId,
      role: this.userDetails?.role,
      email: this.userDetails?.email,
      firstName: this.userDetails?.firstName,
      lastName: this.userDetails?.lastName,
      accessCount: this.userDetails?.accessCount,
      createdAt: this.userDetails?.createdAt,
      deleted: this.userDetails?.deleted,
      deletedAt: this.userDetails?.deletedAt,
      id: this.userDetails?.id,
      inactive: this.userDetails?.inactive,
      lastAccessTS: this.userDetails?.lastAccessTS,
      orgName: this.userDetails?.orgName,
      tenantId: this.userDetails?.tenantId,
      uid: this.userDetails?.uid,
      updatedAt: this.userDetails?.updatedAt,
      emailVerifyKey: this.userDetails?.emailVerifyKey,
      verifyLinkSentAt: this.userDetails?.verifyLinkSentAt,
    });
  }


  resetPassword() {
    if (this.resetPasswordForm.valid) {
      let body = this.userDetails
      body.oldPassword = this.resetPasswordForm.controls['oldPassword'].value
      body.password = this.resetPasswordForm.controls['password'].value
      body.rptPassword = this.resetPasswordForm.controls['rptPassword'].value
      this.dataService.ChangePassword(body).subscribe({
        next: (result) => {
          if (result.failed) {
            this.displayAlertMessage('Current Password is Wrong!', 'error', 'danger');
          } else {
            this.modalService.dismissAll();
            this.resetPasswordForm.reset();
            // this.getProfileSettingDataToPatch()
            this.displayAlertMessage('Success', 'success', 'success');
          }
        },
        error: (error) => {
          this.displayAlertMessage('Current Password is Wrong!', 'error', 'danger');
        },
        complete: () => { },
      })
    } else {
      this.resetPasswordForm.markAllAsTouched()
    }
  }

  // getProfileSettingDataToPatch(){    
  //   this.dataService.GetUserProfile({userId:this.userDetails.id}).subscribe({
  //     next: (result) => {
  //       if (result.status=='failed') {
  //       } else {
  //         this.userDetails = this.sessionService.SessionService_GetUserDetails();
  //         this.imageUrl=this.userDetails?.pictureUrl??result.data.pictureUrl ;
  //         this.userDetails.pictureUrl = this.imageUrl;                  
  //         this.sessionService.SessionService_SetUserDetails(this.userDetails);
  //       }
  //     },
  //     error: (error) => { }
  //   })
  // }

  submitProfileSettingData() {
    if(this.updateProfileForm.valid){
        this.dataService.editUser(this.updateProfileForm.value).subscribe({
          next: (result) => {
            if (result.status === 'failed') {
              this.displayAlertMessage(result.message,'danger','danger')
            } else {
              this.displayAlertMessage(result.message,'success', 'success');          
              this.userDetails.firstName=result?.props?.user['firstName'];
              this.userDetails.lastName=result?.props?.user['lastName'];
              this.sessionService.SessionService_SetUserDetails(this.userDetails); 
              this.modalService.dismissAll();
            }
          },
          error: (error) => {
            console.error("SubmitProfileSettingData: ERROR ===>>", error);
            this.displayAlertMessage(error.message,'danger','danger')
          },
        });
    } else {
      this.updateProfileForm.markAllAsTouched();
    }
  }

  // updateUserProfile() {
  //   let body = {...this.userDetails}
  //   body.firstName = this.updateProfileForm.controls['firstname'].value
  //   body.lastName = this.updateProfileForm.controls['lastname'].value
  //   body.fullName = body.firstName + " " + body.lastName
  //   delete body.pictureUrl;

  //   this.dataService.UpdateUserProfile(body).subscribe({
  //     next: (result) => {
  //       if (result.failed) {
  //         this.displayAlertMessage('Bad Request!', 'error', 'danger');
  //       }
  //       else {
  //         this.modalService.dismissAll();
  //         this.updateProfileForm.reset();
  //         this.userDetails = result.props.user;
  //         result.props.user.pictureUrl=null;
  //         this.sessionService.SessionService_SetUserDetails(result.props.user)
  //         this.updateProfileFormControls();
  //         this.getProfileSettingDataToPatch()
  //         this.displayAlertMessage('Success', 'success', 'success');
  //       }
  //     },
  //     error: (error) => {
  //       this.displayAlertMessage('Bad Request!', 'error', 'danger');
  //     },
  //     complete: () => { },
  //   }
  //   )
  // }

  // --------------- Change Profile Request ----------------
  // changeProfileImage(event) {
  //   let file = event.target.files[0];

  //   let body = new FormData();
  //   body.append("file", file);
  //   body.append("folder", 'public/admins');
  //   body.append("functionName","UpdateProfilePicture"),
  //   body.append("makePublic", "false");
  //   this.dataService.ChangeProfileImage(body).subscribe({
  //     next: (result) => {
  //       if (result.status == 'failed') { }
  //       else {
  //         this.pictureUrl = result.folderName + "/" + result.fileName ;
  //         console.log(this.pictureUrl,'pictureUrl');
          
  //         // this.getUserProfile()
  //       }
  //     },
  //     error: (error) => { },
  //     complete: () => { }
  //   })

  // }

  toggleCollapse(isButtonClicked: boolean) {
   if (isButtonClicked) {
    this.collapsed = !this.collapsed;
    localStorage.setItem('collapsed', JSON.stringify(this.collapsed));
   } else {
    this.collapsed = localStorage.getItem('collapsed') === 'true' ? true : false;
   }
    this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
  }

  logout() {
    localStorage.clear();
    this.route.navigateByUrl('/authentication/login');
    this.dataService.sideBarItem.next('')
  }

  dismissModal() {
    this.modalService.dismissAll();
    this.updateProfileForm.reset();
    this.resetPasswordForm.reset();
    this.updateProfileFormControls();
    this.resetPasswordFormControls();
  }

  openChangePasswordModal(changePassword) {
    this.modalService.open(changePassword, { centered: true, size: 'md' });
    this.resetPasswordFormControls()
  }

  openUpdateProfileModal(updateProfile) {
    this.modalService.open(updateProfile, { centered: true, size: 'md' });
    this.updateProfileFormPatch();
  }

  displayNotifications() {
    setTimeout(() => {
      this.displayNotification = !this.displayNotification
    }, 100);
  }

  clickOutside() {
    this.displayNotification = false;
  }

  removeLeadingSpaces(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    // Remove leading spaces
    const trimmedValue = inputValue.replace(/^\s+/, '');
    // Update the input value
    if (trimmedValue !== inputValue) {
      inputElement.value = trimmedValue;
      // Update the form control value
     this.searchString=trimmedValue;
    }
  }

  globalSearch(event) {
    let searchValue = event.target.value;
    this.searchString = searchValue;
    // this.searchString = this.searchString.trim();
    if (searchValue.length > 0 && event.keyCode == 13) {
      this.route.navigate(['global-search'],{queryParams : {query : searchValue}})
    } else if (searchValue.length == 0 && event.keyCode == 13) {
      this.route.navigateByUrl('/enterprise-ai');
    }
  }

  loadAvatar(){
      this.imageUrl = '../../../../assets/images/profile.png';
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll()
  }

    // ************************** ALERT MESSAGES ***************************/

  displayAlertMessage(incomingMessage, incomingResponseType, incomingColor) {
    this.displayErrorBlock = true
    this.resMessage = {
      message: incomingMessage,
      responseType: incomingResponseType,
      color: incomingColor
    };
    setTimeout(() => { this.displayErrorBlock = false; }, 3000);
  }
 
}


