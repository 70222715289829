import { INavbarData } from "./helper";

export const navbarData :INavbarData[]= [

    {
        routerLink: '/dashboard',
        path:'/dashboard',
        label: 'Dashboard',
        icon: 'fa-objects-column'
    },
    {
        routerLink: '/jobs',
        path:'/jobs',
        label: 'Jobs',
        icon:"fa-briefcase"
    },
    {
        routerLink: '',
        label: 'Reports',
        icon:"fa-chart-mixed",
        items: [ 
            {
                routerLink: '/reports/bfs-report',
                path:'reports/bfs-report',
                label: 'B,F,S Report'
            },
            {
                routerLink: '/reports/h-report',
                path:'reports/h-report',
                label: 'H Report'
            },
        ]

    },
    {
        routerLink: '',
        label: 'Coordinators',
        icon: "fa-person-sign",
        items: [ 
            {
                routerLink: '/coordinators/customers',
                path:'coordinators/customers',
                label: 'Customers'
            },
            {
                routerLink: '/coordinators/reps',
                path:'coordinators/reps',
                label: 'Reps'
            },
            {
                routerLink: '/coordinators/consignees',
                path:'coordinators/consignees',
                label: 'Consignees'
            },
            {
                routerLink: '/coordinators/bulk-upload',
                path:'coordinators/bulk-upload',
                label: 'Bulk Upload'
            }
        ]

    },
    {
        routerLink: '',
        label: 'Admin',
        icon: 'fa-user',
        items: [ 
            {
                routerLink: '/admin/products',
                path:'admin/products',
                label: 'Products'
            },
            {
                routerLink: '/admin/atms',
                path:'admin/atms',
                label: 'ATMs'
            },
            {
                routerLink: '/admin/distributors',
                path:'admin/distributors',
                label: 'Distributors'
            },
            {
                routerLink: '/admin/users',
                path:'admin/users',
                label: 'Users'
            }
        ]

    },
]